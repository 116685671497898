import { useState, useCallback } from 'react';

export const useLoadingState = () => {
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isLoadingObject: false,
    isLoadingItem: false,
    isLoadingList: false,
    isFabricating: false,
    isFetchingLinks: false,
  });
  const getLoading = useCallback((key) => loadingState[key], [loadingState]);
  const setLoading = useCallback((key, value) => {
    if (key === 'isLoadingItem') {
      setLoading('isLoadingObject', value);
    }
    setLoadingState(prevState => ({ ...prevState, [key]: value }));
  }, []);

  return { 
    getLoading, 
    setLoading,
    isLoading: loadingState.isLoading,
    isLoadingObject: loadingState.isLoadingObject,
    isLoadingItem: loadingState.isLoadingObject,
    isLoadingList: loadingState.isLoadingList,
    isFabricating: loadingState.isFabricating,
    isFetchingLinks: loadingState.isFetchingLinks,
   };
};
