import { v4 as uuidv4 } from 'uuid';
import PortalConfiguration from '../configuration/config';

class RealmObject {
  constructor(incomingData = {}, realmID) {
    try{
      incomingData.payload.template = {
        templateID: incomingData.templateID, 
        collectionID: incomingData.collectionID};
      this.RealmID = realmID || incomingData.RealmID; 
      this.RealmObjectID = incomingData.RealmObjectID || uuidv4();
      this.name = incomingData.name;
      this.parentID = incomingData.parentID || PortalConfiguration.NoParentID;
      this.payload = this.normalizePayload(incomingData.payload);
      this.publishedLevelACL = incomingData.publishedLevelACL || 'PRIVATE';
      this.tenant = incomingData.tenant;
      this.created = new Date(incomingData.created || Date.now()).toUTCString();
      this.updated = new Date(incomingData.updated || Date.now()).toUTCString();
      this.referenceID = incomingData.referenceID || '';
    } catch (error) {
      console.error("Error creating RealmObject:", error);
    }
}


  // Method to normalize payload to handle arrays and objects
  normalizePayload(payload) {
    if (payload.Questions && !Array.isArray(payload.Questions)) {
      // If Questions is an object, wrap it in an array
      payload.Questions = [payload.Questions];
    } 
    if (payload.ContextAttribute && !Array.isArray(payload.ContextAttribute)) {
      // If ContextAttribute is an object, wrap it in an array
      payload.ContextAttribute = [payload.ContextAttribute];
    }
    return payload;
  }

  // Method to update the payload safely
  updatePayload(newPayload) {
    for (let key in newPayload) {
      if (Array.isArray(newPayload[key])) {
        // Assume the key is a sub-key (e.g., 'Questions') that should replace the existing array
        this.payload[key] = [...newPayload[key]];
      } else {
        // Merge newPayload with the existing payload
        this.payload = { ...this.payload, ...newPayload };
      }
    }
    this.updated = new Date().toUTCString();
  }
  setPayload(newPayload) {
    this.payload = newPayload;
    this.updated = new Date().toUTCString();
  }
  setParentID(newParentID) {
    this.parentID = newParentID;
    this.updated = new Date().toUTCString();
  }
  setPublishedLevelACL(newPublishedLevelACL) { 
    this.publishedLevelACL = newPublishedLevelACL;
    this.updated = new Date().toUTCString();
  }
  // Method to set a new name
  setName(newName) {
    this.name = newName;
    this.updated = new Date().toUTCString();
  }

  // Method to set a new tenant
  setTenant(newTenant) {
    this.tenant = newTenant;
    this.updated = new Date().toUTCString();
  }
  setReferenceID(newReferenceID) {
    this.referenceID = newReferenceID;
    this.updated = new Date().toUTCString();
  }


  // Method to get a summary of the object
  getSummary() {
    return {
      RealmID: this.RealmID,
      RealmObjectID: this.RealmObjectID,
      templateID: this.templateID,
      name: this.name,
      tenant: this.tenant,
      created: this.created,
      updated: this.updated,
    };
  }
}

export default RealmObject;
