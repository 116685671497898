import React, { useState, useEffect } from "react";
import { Row, Col, Button, Space , Typography, Grid} from "antd";
import "./css/StoryEditor.css";
import KeyValuesList from "./KeyValuesList";
import LogoutButton from "./logoutButton";
import PortalConfiguration from "../configuration/config";
import { useAuthContext } from "../contexts/AuthProvider";
import LoginForm from "./loginform";
import ChangePasswordForm from "./ChangePasswordForm"; // Import the new component
import { ExportOutlined } from "@ant-design/icons";
import CampaignFabricatorAuthTokens from "../configuration/AuthorizationTokens";



const UserStatusPanel = ({ drawerVisible }) => {
  const { useBreakpoint } = Grid;
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isPasswordFormVisible, setIsPasswordFormVisible] = useState(false); // State to show/hide password form
  const { changePassword, loggedIn, userPermissionLevel } = useAuthContext(); // Get context values

  const screens = useBreakpoint();

  const onFinish = (values) => {
    setLoading(true);
    changePassword(values.newPassword, values.newPasswordCheck)
      .then(() => {
        setLoading(false);
        setIsPasswordFormVisible(false); // Hide form after successful password change
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setIsPasswordFormVisible(false); // Hide form on cancel
    setError(null); // Clear any errors
  };

  useEffect(() => {
    if (!drawerVisible) {
      setError(null); // Clear any errors
      setIsPasswordFormVisible(false); // Reset form visibility when the drawer is closed
    }
  }, [drawerVisible]);

  return (
    <>
      {loggedIn ? (
        <Row gutter={[16, 16]} style={{ padding: "16px" }}>
          <Col span={24}>
            <LogoutButton />
          </Col>

          {/* Button to show the password change form */}
          <Col span={24}>
            {!isPasswordFormVisible && (
              <Button
                type="primary"
                onClick={() => setIsPasswordFormVisible(true)}
                className="primary"
              >
                Change Password
              </Button>
            )}
          </Col>

          {/* Conditionally render the ChangePasswordForm and pass handleCancel */}
          {isPasswordFormVisible && (
            <Col span={24}>
              <ChangePasswordForm
                onFinish={onFinish}
                loading={loading}
                drawerVisible={drawerVisible}
                handleCancel={handleCancel} // Pass cancel handler to the form
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </Col>
          )}

        </Row>
      ) : (
        <LoginForm />
      )}
      {userPermissionLevel === CampaignFabricatorAuthTokens.PLAYER && (
      <Row gutter={[16, 16]} style={{ padding: "16px" }}>
        <Col span={24}>
          <Space direction="vertical" align="left element-margined-right-medium">
            <a href="http://campaignfabricator.com" target="_blank" rel="noopener noreferrer">
              <Button 
                type="primary" 
                icon={<ExportOutlined />} 
                shape={window.innerWidth < 576 ? "circle" : "default"} // Show icon-only on small screens
                className="primary"
              >
                {window.innerWidth >= 576 && "Upgrade"} {/* Show text on medium and larger screens */}
              </Button>
            </a>
            <Text type="secondary" className='text-secondary' style={{ fontSize: '12px' }}>
              Get your own realm and create your own world.
            </Text>
          </Space>
        </Col>
      </Row>
      )}

      {PortalConfiguration.env === "qa" && (
        <Col span={24}>
          <KeyValuesList />
        </Col>
      )}
    </>
  );
};

export default UserStatusPanel;
