import PortalConfiguration from '../configuration/config';
import ApiCaller from './ApiCaller';
import AccessObject from '../models/accessControl'; // Adjust this import to the actual path

class AccessControlService {
  
  // Create a new access record
  static async createAccess(apiCaller, sessionID, AccessObject) {
    try {

      const result = await apiCaller.callApi({
        authorization: sessionID,
        service: '/', // Assuming the lambda is triggered by /links/access
        method: 'PUT',
        body: AccessObject,
        headers: {
          applicationID: PortalConfiguration.defaultApplicationID, 
        },
      });
      return result; // Returning the saved record from the API
    } catch (error) {
      console.error('Error creating access:', error);
      throw new Error(error.response?.data || { error: 'Failed to create access' });
    }
  }

  // Delete an access record by accessId and targetId
  static async deleteAccess(apiCaller, sessionID, accessId, targetId) {
    try {
      const result = await apiCaller.callApi({
        authorization: sessionID,
        service: '/',
        method: 'DELETE',
        params: {
            accessId: accessId,
            targetId: targetId,
        },
        headers: {
          applicationID: PortalConfiguration.defaultApplicationID,
        },
      });
      return result; // Returning the delete confirmation from the API
    } catch (error) {
      console.error('Error deleting access:', error);
      throw new Error(error.response?.data || { error: 'Failed to delete access' });
    }
  }

  // Retrieve access records by primaryId (query for accessors)
  static async getTargetList(apiCaller, sessionID, userId) {
    try {
      const result = await apiCaller.callApi({
        authorization: sessionID,
        service: '/accesslist/accessor',
        method: 'GET',
        params: {
          accessId: userId,
        },
        headers: {
          applicationID: PortalConfiguration.defaultApplicationID,
        },
      });
      return result.accessList; // Return the list of records
    } catch (error) {
      console.error('Error fetching accessors:', error);
      throw new Error(error.response?.data || { error: 'Failed to fetch accessors' });
    }
  }

  // Retrieve access records by primaryId (query for targets)
  static async getAccessorList(apiCaller, sessionID, targetId) {
    try {
      const result = await apiCaller.callApi({
        authorization: sessionID,
        service: '/accesslist/target',
        method: 'GET',
        params: {
          targetId: targetId, // Assuming it's a GSI query
        },
        headers: {
          applicationID: PortalConfiguration.defaultApplicationID,
        },
      });
      return result.accessList; // Return the list of records
    } catch (error) {
      console.error('Error fetching targets:', error);
      throw new Error(error.response?.data || { error: 'Failed to fetch targets' });
    }
  }
}

export default AccessControlService;
