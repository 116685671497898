// src/components/LogoutButton.js
import React from 'react';
import { Button, message } from 'antd';
import { useAuthContext } from '../contexts/AuthProvider';

const LogoutButton = () => {
    const { logout } = useAuthContext();

    const handleLogout = async () => {
        try {
            logout();
        } catch (error) {
            message.error('Logout failed');
        }
    };

    return (
        <Button type="primary" onClick={handleLogout} className="ant-btn-rounded primary primary-small">
            Logout
        </Button>
    );
};

export default LogoutButton;
