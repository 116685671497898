import React, { useEffect } from 'react';
import { Tree, Typography } from 'antd';
import useAccessTreeHandler from '../hooks/useAccessTreeHandler'; // Custom hook
import { useCampaignsContext } from '../contexts/PlayerCampaignsProvider';
import './css/RealmObjectsDisplay.css';
import './css/TreeControl.css';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider'; // Custom context
import { useRealmContext } from '../contexts/RealmProvider';
import { useAppContext } from '../contexts/AppProvider'; // Custom context

const CampaignTree = ({ setContentLoading, title, myMode = 'campaign' }) => {
  const { Title } = Typography;
  const { mode, setMode } = useAppContext();
  const { setLoading, setAccessDenied, campaigns } = useAccessObjectContext();

  const { treeData, expandedKeys, setExpandedKeys } = useAccessTreeHandler(campaigns);
  const { campaign, setCampaign } = useCampaignsContext();
  
  const onSelect = (selectedKeys, { node }) => {
    try {
      // Check if node is a leaf (i.e., it has no children)
      if (!node.children || node.children.length === 0) {
        if (selectedKeys.length > 0) {
          setAccessDenied(false);
          setContentLoading(true);
          setCampaign(selectedKeys[0]);
          setMode(myMode);
        }
      }
    } catch (error) {
      console.error('Error selecting RealmObject:', error);
    }
  };

  useEffect(() => {
    if (mode !== 'campaign') {
      setCampaign(null);
    }
  }, [mode]);

  return (
    <>
      <Title level={3} className='custom-title element-margined-small'>{title}</Title>
      <Tree
        className="realm-object-tree"
        treeData={treeData}
        selectedKeys={[campaign?.PK_CampaignID]}
        expandedKeys={expandedKeys}
        onExpand={setExpandedKeys}
        onSelect={onSelect}
        expandAction="click" // Expand node on entire row click
      />
    </>
  );
};

export default CampaignTree;
