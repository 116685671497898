import React, { useState, useEffect } from 'react';
import { Typography, Space } from 'antd';
import MarkdownPreview from '@uiw/react-markdown-preview';
import './css/StoryPresenter.css';
import './css/MarkDownStyles.css';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';

const StoryPresenter = ({ fieldKey, StoryLabel, storySize = 'small-story' }) => {
  const { Title } = Typography;

  // Access the realmObject from RealmObjectProvider
  const { realmObject } = useAccessObjectContext();
  const [localContent, setLocalContent] = useState(realmObject?.payload?.[fieldKey] || "let the story unfold..."); // Local state for editing

  // Sync content with realmObject when it changes
  useEffect(() => {
    const initialContent = realmObject?.payload?.[fieldKey] || "unknown";
    if (localContent !== initialContent) {
      setLocalContent(initialContent); // Update local content only if it has changed
    }
  }, [realmObject, fieldKey, localContent]);

  return (
    <Space className="story-presenter">
      <Title className="custom-title" level={5}>{StoryLabel}</Title>
      <MarkdownPreview
        className="markdown-previewer"
        style={{ border: '1px solid', borderRadius: '8px' }}
        source={localContent}
      />
    </Space>
  );
};

export default StoryPresenter;
