import React, { useState } from 'react';
import { List, Button, Form, Input, Card, Typography, message, Popconfirm, Modal, Space } from 'antd'; // Ensure Modal is imported
import { useCampaignsContext } from '../contexts/PlayerCampaignsProvider'; // Import the Campaign context
import { useRealmContext } from '../contexts/RealmProvider'; // Import the Realm context
import CampaignRecordService from '../services/CampaignRecordService'; // Import the Campaign service
import { DeleteOutlined, UserAddOutlined, WarningOutlined, ReloadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating unique IDs
import './css/InputField.css';
import MarkdownPreview from '@uiw/react-markdown-preview';
import AccessObject from '../models/accessControl'; // Adjust this import to the actual path
import useAccessControl from '../hooks/useAccessControl'; // Import the custom hook for access control
import { useAuthContext } from '../contexts/AuthProvider';
import CampaignPlayerList from './CampaignPlayerList'; // Import the Campaign player list component
import {
  BoldItalicUnderlineToggles,
  UndoRedo,
  MDXEditor,
  toolbarPlugin,
  listsPlugin,
  ListsToggle,
  tablePlugin,
  InsertTable,
  BlockTypeSelect,
  headingsPlugin,
  quotePlugin,
  linkPlugin,
  linkDialogPlugin,
  Separator,
  thematicBreakPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import './css/CampaignManager.css';

const { Title, Text } = Typography;


const CampaignDetails = () => {
  const { username } = useAuthContext(); // Get the current user ID
  const {
    campaign,
    campaignUsers,
    campaignNotes,
    updateCampaignUsers,
    updateCampaignNotes,
  } = useCampaignsContext();

 
  const { realmID } = useRealmContext();
  const campaignService = CampaignRecordService();
  const { createAccess, deleteAccess } = useAccessControl(); // Use your custom hook for creating access
  // Forms for adding users and notes
  const [userForm] = Form.useForm();
  const [noteForm] = Form.useForm();

  // State for managing MDX Editor content
  const [noteContent, setNoteContent] = useState('');

  // State for handling validation errors
  const [errorMessage, setErrorMessage] = useState('');

  // Handle MDX Editor content changes
  const handleContentChange = (newValue) => {
    setNoteContent(newValue);
  };

  

  // Handlers for adding a note
  const handleAddNote = async () => {
    const newNoteId = uuidv4(); // Generate a unique ID for the note
    const noteData = {
      content: noteContent,
    };

    try {
      
      console.log("Adding Note:", realmID, campaign, newNoteId, noteData, username);
      await campaignService.addNotesToCampaign(realmID, campaign, newNoteId, noteData, username);
      message.success('Note added successfully!');
      noteForm.resetFields();
      setNoteContent(''); // Reset the MDX editor content
      // Refresh the notes list
      updateCampaignNotes(campaign);
    } catch (error) {
      console.error('Error adding note:', error);
      message.error('Failed to add note.');
    }
  };

  // Handlers for deleting a note
  const handleDeleteNote = async (noteId) => {
    try {
        console.log("Deleting Note:", noteId);
      await campaignService.deleteNotesFromCampaign(realmID, campaign, noteId);
      message.success('Note deleted successfully!');
      // Refresh the notes list
      updateCampaignNotes(campaign);
    } catch (error) {
      console.error('Error deleting note:', error);
      message.error('Failed to delete note.');
    }
  };
 
  // Show delete confirmation for notes
  const showDeleteNoteConfirm = (note) => {
    Modal.confirm({
      title: `Are you sure you want to delete this note?`,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'info',
      cancelText: 'Cancel',
      onOk() {
        handleDeleteNote(note.SK_RecordID);
      },
    });
  };

  const refreshNotes = () => {
    updateCampaignNotes(campaign);
    };

  // Configure the toolbar plugin with all the required features
  const toolbar = toolbarPlugin({
    toolbarContents: () => (
      <>
        <BlockTypeSelect />
        <BoldItalicUnderlineToggles />
        <Separator />
        <ListsToggle />
        <InsertTable />
        <Separator />
        <UndoRedo />
      </>
    ),
  });

  // Include all necessary plugins
  const plugins = [
    toolbar,
    headingsPlugin(),
    quotePlugin(),
    linkPlugin(),
    linkDialogPlugin(),
    listsPlugin(),
    tablePlugin(),
    thematicBreakPlugin(),
  ];

  return (
    <div className="campaign-panel-main">
      {campaign && (
        <>
          {/* Players Section */}
          
          <CampaignPlayerList campaignUsers={campaignUsers}
            userForm={userForm}
            errorMessage={errorMessage} />

          {/* Notes Section */}
          <Card className="fab-panel-card bottom-card" title={
                <Title level={4} className="custom-title center-title">Notes</Title>
                }>
                
            {/* MDX Editor for adding a note */}
            <Form layout="vertical" onFinish={handleAddNote} form={noteForm}>
              <Form.Item name="note">
                <MDXEditor
                  plugins={plugins}
                  markdown={noteContent}
                  onChange={handleContentChange}
                  className="custom-editor"
                  contentEditableClassName="custom-markdown"
                />
              </Form.Item>
              <Form.Item>
                <Space direction='horizontal'>
                    <Button type="primary" htmlType="submit">
                    Add Note
                    </Button>
                    <Button icon={<ReloadOutlined />} type="link" onClick={() => refreshNotes()} />
                </Space>
              </Form.Item>
            </Form>

            {/* List of Notes */}
            <List
              locale={{ emptyText: <Text className='text-secondary'>No notes added yet.</Text> }}
              dataSource={campaignNotes}
              renderItem={(note) => (
                <List.Item
                actions={
                    username === note.payload.author 
                      ? [<Button icon={<DeleteOutlined />} type="link" onClick={() => showDeleteNoteConfirm(note)} />]
                      : []
                  }
                >
                  <Space direction='vertical'>
                    <MarkdownPreview className="markdown-previewer" style={{ border: '1px solid', borderRadius: '8px' }} source={note.payload.content} />
                    <Text className='text-secondary'>By: {note.payload.author || 'anonymous'}</Text>
                  </Space>
                </List.Item>
              )}
            />
          </Card>
        </>
      )}
    </div>
  );
};

export default CampaignDetails;
