import React, { createContext, useState, useContext, useEffect, useCallback, useMemo, useRef } from 'react';
import ApiCaller from '../services/ApiCaller';
import { useAppContext } from './AppProvider';
import RealmObject from '../models/RealmObject';
import PortalConfiguration from '../configuration/config';
import { useRealmContext } from './RealmProvider';
import { useAuthContext } from './AuthProvider';

const AccessObjectContext = createContext();

export const useAccessObjectContext = () => {
  const context = useContext(AccessObjectContext);
  if (!context) {
    throw new Error('useAccessObjectContext must be used within an AccessObjectProvider');
  }
  return context;
};

export const AccessObjectProvider = ({ children }) => {
  
  const [realmObjectID, setRealmObjectIDForReals] = useState(null);
  const [accessDenied, setRealAccessDenied] = useState(false);
  const [realmObject, setRealmObject] = useState(null);
  const [realmobjectList, setRealmObjectList] = useState([]);
  
  const [realmObjectSource, setRealmObjectSource] = useState(null);
  const [accessObjectList, setAccessObjectList] = useState([]);
  const [incomingRealmObjectLinks, setIncomingRealmObjectLinks] = useState([]);
  const [outGoingRealmObjectLinks, setOutGoingRealmObjectLinks] = useState([]);
  const [realmObjectEdited, setRealmObjectEdited] = useState(false);

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isLoadingItem: false,
    isFetchingLinks: false,
    isFetchingAccessList: false,
  });

  const setAccessDenied = useCallback((value) => {
    console.log('Setting access denied:', value);
    setRealAccessDenied(value);
  }, []);
  
  const { referenceID } = useAppContext();
  const { realmID, setRealmID } = useRealmContext();
  const { sessionID, username } = useAuthContext();

  // Memoize the apiCaller to avoid unnecessary re-creation on every render
  const apiCaller = useMemo(() => new ApiCaller(PortalConfiguration.realmobjectsURL), []);

  const realmObjects = useCallback(() => {
    const retValue = accessObjectList.filter((accessObject) => accessObject.targetType === 'realmObject');
    console.log('RealmObjects:', retValue, accessObjectList);
    return retValue;
  }, [accessObjectList]);

  const campaigns = useCallback(() => {
    return accessObjectList.filter((accessObject) => accessObject.targetType === 'campaign-access');
  }, [accessObjectList]);

  const setRealmObjectID = useCallback((id) => {
    setRealmObjectIDForReals(id);
  }, []);

  // UseCallback to memoize setLoading function
  const setLoading = useCallback((key, value) => {
    setLoadingState(prevState => ({ ...prevState, [key]: value }));
  }, []);

  const handleApiError = useCallback((error, action) => {
    console.error(`Failed to ${action} RealmObject: ${error.message}. Please contact support.`);
  }, []);

  const fetchRealmObjectByReferenceID = useCallback(async () => {
    console.log('Getting RealmObject by referenceID:', referenceID);
    try {
      const response = await apiCaller.callApi({
        userID: username || null,
        service: '/reference',
        method: 'GET',
        authorization: null,
        params: { referenceID },
      });
      if (response) {
        const realmObject = new RealmObject(response);
        setRealmID(realmObject.RealmID);
        setRealmObjectID(realmObject.RealmObjectID);
        setRealmObject(realmObject);
        setRealmObjectSource('reference');
        setRealmObjectEdited(false);
        setAccessDenied(false);
      } else {
        console.log('Expected object but got:', response);
        setRealmID(null);
        setRealmObjectID(null);
        setRealmObject(null);
        setRealmObjectSource('reference');
        setRealmObjectEdited(false);
        setAccessDenied(true);
      }
    } catch (error) {
      handleApiError(error, 'fetching by referenceID');
      setRealmID(null);
      setRealmObjectID(null);
      setRealmObject(null);
      setRealmObjectSource('reference');
      setRealmObjectEdited(false);
      setAccessDenied(true);
    }
  }, [referenceID, apiCaller, username, setRealmID, setRealmObjectID, setAccessDenied, handleApiError]);

  const fetchRealmObject = useCallback(async (realmId, realmObjectId) => {
    console.log('Fetching RealmObject:', realmObjectID);
    try {
      const response = await apiCaller.callApi({
        userID: username,
        service: '', // Replace with your endpoint
        method: 'GET',
        authorization: sessionID,
        params: { RealmID: realmId, RealmObjectID: realmObjectId },
      });
      console.log('Got RealmObject:', response);
      if (response && response.name) {
        console.log('Got RealmObject:', response);
        setRealmObject(new RealmObject(response)); // Set the realmObject state here
        setAccessDenied(false);
        setRealmObjectEdited(false);
      } else {
        console.error('Expected object but got:', response);
        setRealmID(null);
        setRealmObjectID(null);
        setRealmObject(null);
        setRealmObjectEdited(false);
        setAccessDenied(true);
      }
    } catch (error) {
      handleApiError(error, 'fetching');
      setRealmID(null);
      setRealmObjectID(null);
      setRealmObject(null);
      setRealmObjectEdited(false);
      setAccessDenied(true);
    }
  }, [realmObjectID, apiCaller, username, sessionID, setAccessDenied, setRealmID, setRealmObjectID, handleApiError]);

  const realmObjectIDRef = useRef(realmObjectID);

  useEffect(() => {
    realmObjectIDRef.current = realmObjectID;
  }, [realmObjectID]);

  useEffect(() => {
    console.log('referenceID changed. Fetching realm object', referenceID);
    if (referenceID) {
      fetchRealmObjectByReferenceID();
    }
  }, [referenceID]);

  // Memoize context values
  const contextValue = useMemo(() => ({
    realmObject,
    realmObjectID,
    fetchRealmObject,
    accessObjectList,
    isLoading: loadingState.isLoading,
    isLoadingItem: loadingState.isLoadingItem,
    isFabricating: loadingState.isFabricating,
    isFetchingLinks: loadingState.isFetchingLinks,
    setLoading,
    realmObjectEdited,
    setAccessObjectList,
    setRealmObjectEdited,
    setRealmObjectID,
    setRealmObject,
    fetchRealmObjectByReferenceID,
    incomingRealmObjectLinks,
    setIncomingRealmObjectLinks,
    outGoingRealmObjectLinks,
    setOutGoingRealmObjectLinks,
    realmObjectSource,
    setRealmObjectSource,
    accessDenied, 
    setAccessDenied,
    realmObjects,
    campaigns
  }), [realmObject, realmObjectID, fetchRealmObject, accessObjectList, loadingState.isLoading, loadingState.isLoadingItem, loadingState.isFabricating, loadingState.isFetchingLinks, setLoading, realmObjectEdited, setRealmObjectID, fetchRealmObjectByReferenceID, incomingRealmObjectLinks, outGoingRealmObjectLinks, realmObjectSource, accessDenied, setAccessDenied, realmObjects, campaigns]);

  return (
    <AccessObjectContext.Provider value={contextValue}>
      {children}
    </AccessObjectContext.Provider>
  );
};

export default AccessObjectProvider;
