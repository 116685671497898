import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Layout, Grid, Button, Drawer, Typography } from 'antd';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import { useCampaignsContext } from '../contexts/PlayerCampaignsProvider';
import { useAppContext } from '../contexts/AppProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import useAccessControl from '../hooks/useAccessControl';
import StoryPresenter from './StoryPresenter';
import SiteHeader from './SiteHeader';
import IconifiedTitle from './IconifiedTitle';
import NothingToSeeYet from './NothingToSeeYet';
import CustomSpinner from './CustomSpinner';
import RealmObjectTree from './RealmObjectTree';
import CampaignTree from './CampaignTree';
import EmptyControlContent from './EmptyControlContent';
import { RealmObjectManager } from '../managers/RealmObjectManager';
import RealmObjectViewer from './RealmObjectViewer'; 
import CampaignDetails from './CampaignDetails';
import {useRealmContext} from '../contexts/RealmProvider';
import './css/RealmObjectViewer.css';
import './css/RealmObject.css';
import './css/SiteHeader.css';

const ContentViewer = () => {
  const { Text, Title } = Typography;
  const { Header, Content, Sider } = Layout;
  const screens = Grid.useBreakpoint();

  const { realmObject, isLoadingItem, accessDenied } = useAccessObjectContext();
  const { campaign }  = useCampaignsContext();

  const { getTargetList } = useAccessControl();
  
  const { referenceID, mode, setMode } = useAppContext();
  const { realmID } = useRealmContext();
  const { loggedIn, username } = useAuthContext();
  
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [siderVisible, setSiderVisible] = useState(true);
  const [loadingObjectViewer, setRealLoadingObjectViewer] = useState(false);

  
  const setLoadingObjectViewer = (value) => {
    setRealLoadingObjectViewer(value);
  }

  useEffect(() => {
    if (mode === 'realmObject' || mode === 'referenceID')
      setLoadingObjectViewer(false);
  }, [realmObject]);

  useEffect(() => {
    if (loggedIn) getTargetList(username);
  }, [loggedIn]);

  useEffect(() => {
    if (!screens.md) {
      setSiderCollapsed(true);
      setSiderVisible(false);
    } else {
      setSiderCollapsed(false);
      setSiderVisible(true);
    }
  }, [screens]);

  const toggleSiderVisible = () => {
    if (loggedIn) setSiderVisible(!siderVisible);
    else setSiderVisible(false);
  };

  if (isLoadingItem) {
    return (
      <div className="loading-container">
        <CustomSpinner />
      </div>
    );
  }

  if (!referenceID && !loggedIn) {
    return (
      <>
        <div className="full-width-header">
          <SiteHeader />
        </div>
        <NothingToSeeYet />
      </>
    );
  }

  if (mode !== 'campaign' && referenceID && !realmObject && !loggedIn && !accessDenied) {
    return (
      <div className="loading-container">
        <CustomSpinner />
      </div>
    );
  }

  var iconifiedTitleProps = {};
  if (accessDenied) {
    iconifiedTitleProps = {
      title: 'Access Denied',
      iconFileIndex: 0,
      iconIndex: 0,
      iconDisplaySize: 45,
      titleSize: 'large',
      titleEditable: false,
    };
  } else {
    iconifiedTitleProps = realmObject
      ? {
          title: realmObject?.name || 'Unnamed',
          iconFileIndex: realmObject?.payload.iconFileIndex || 0,
          iconIndex: realmObject?.payload.iconIndex || 0,
          iconDisplaySize: 45,
          titleSize: 'large',
          titleEditable: true,
        }
      : {};
  }

  // Render content based on the mode using a switch statement
  let content;
  switch (mode) {
    case 'campaign':
      content = <><CampaignDetails/></>;

      break;
    case 'realmObject':
      content = realmObject && !accessDenied ? (
        <RealmObjectViewer realmObject={realmObject} accessDenied={accessDenied} />
      ) : (
        loadingObjectViewer && !accessDenied ? (
          <div className="loading-container">
            <CustomSpinner />
          </div>
        ) : (
          <EmptyControlContent
            emptyText={
              accessDenied
                ? "The object you are trying to load is currently not available to you."
                : "Select an item from the left, or ask your Game Master to share something!"
            }
          />
        )
      );
      break;
      case 'referenceID':
        content = (
          <RealmObjectViewer realmObject={realmObject} accessDenied={accessDenied} />
        );
        break;
    default:
      content = (
        <EmptyControlContent
          emptyText="Select an item from the left, or ask your Game Master to share something!"
        />
      );
  }

  return (
    <Layout>
      <RealmObjectManager />
      <SiteHeader />
      <Content className="test-style">
        <Layout className="layout-container">
          {screens.lg && loggedIn && (
            <Sider width="300px" className="left-sider">
              <RealmObjectTree setContentLoading={setLoadingObjectViewer} title='Shared Realm Content'/>
              <CampaignTree setContentLoading={setLoadingObjectViewer} title='Campaigns'/>
            </Sider>
          )}
          {!screens.lg && loggedIn && !siderVisible && (
            <Button onClick={toggleSiderVisible} className="primary toggle-sider-button">
              Show Shared Content
            </Button>
          )}
          {!screens.lg && siderVisible && (
            <Drawer title="Shared Content" placement="left" onClose={toggleSiderVisible} open={siderVisible}>
              <RealmObjectTree setContentLoading={setLoadingObjectViewer} title='Realms' myMode='realmObject'/>
              <CampaignTree setContentLoading={setLoadingObjectViewer} title='Campaigns'/>
            </Drawer>
          )}
          <Content className="layout-content">
            {content}
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default ContentViewer;
