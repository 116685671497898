
class PortalConfiguration {

  static env = "prod";
  static enviornmentPrefix = PortalConfiguration.env === "qa" ? "qa-" : PortalConfiguration.env === "dev" ? "dev-" : PortalConfiguration.env === "prod" ? "" : "SandBox-";
  static usersettingsURL= 'https://'+PortalConfiguration.enviornmentPrefix+'user-settings.aoendpoint.com';
  static templatesURL= 'https://'+PortalConfiguration.enviornmentPrefix+'template.aoendpoint.com';
  static packagesURL= 'https://'+PortalConfiguration.enviornmentPrefix+'package.aoendpoint.com';
  static fabricationURL='https://'+PortalConfiguration.enviornmentPrefix+'fabricator.aoendpoint.com';
  static realmobjectsURL='https://'+PortalConfiguration.enviornmentPrefix+'realmobjects.aoendpoint.com';
  static linkURL = 'https://'+PortalConfiguration.enviornmentPrefix+'link.aoendpoint.com';
  static authorizationURL = 'https://'+PortalConfiguration.enviornmentPrefix+'authorization.aoendpoint.com';
  static accessControlURL = 'https://'+PortalConfiguration.enviornmentPrefix+'accesscontrol.aoendpoint.com';
  static campaignServiceURL = 'https://'+PortalConfiguration.enviornmentPrefix+'campaign.aoendpoint.com';
  static imageDeleteURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-delete.aoendpoint.com';
  static imageUploadURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-upload.aoendpoint.com';
  static imageDownloadURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-download.aoendpoint.com';
   
  static readerURL = "https://reader.campaignfabricator.com";
  static version = "01.002.0003"

  static defaultAccountID = "CampaignFabricator-001";
  static defaultApplicationID  = "08-08-2024AOCF";
  static NoParentID = "00000000-0000-0000-0000-000000000000"

   static getDomain() {
     return PortalConfiguration.domain;
   }
   static getenv() {
     return PortalConfiguration.env;
   }
   static getusersettingsURL() {
     return PortalConfiguration.usersettingsURL;
   }
 
   static getdefaultAccountID() {
     return PortalConfiguration.defaultAccountID;
   }
   static getdefaultApplicationID() {
     return PortalConfiguration.defaultApplicationID;
   }
 }

export default PortalConfiguration;
 
export class UserRecordInput {
   username;
   accountId;
   payload;
   password;
   authorizationProfile;
   constructor(username, accountId, payload,password,authorizationProfile) {
       this.username = username;
       this.accountId = accountId;
       this.payload = payload;
       this.password = password;
       this.authorizationProfile = authorizationProfile;
   }
}

export class CampaignServiceStatics
{
// dynamo attributes
   // Primary Table
   static dynamoPrimaryTableDesignator = "campaign";
   static dynamoPrimaryTablePartitionKey = "PK_CampaignID";
   static dynamoPrimaryTableSortKey = "SK_RecordID";
   static dynamoPrimaryTableSecondaryKeys = ["created","updated","type"];
   static dynamoPrimaryTableGlobalSecondaryKey = "GSI_PK_REALMID";
   static dynamoPrimaryTableGlobalSecondarySortKey = "GSI_SK_CAMPAIGNRECORDID";
}

