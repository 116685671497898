import { useState, useCallback, useMemo } from 'react';
import ImageService from '../services/ImageService';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config';
import { message } from 'antd'; // Importing message for notifications
import { useLoadingState } from './useLoadingState'; // Assuming useLoadingState exists
import { useAuthContext } from '../contexts/AuthProvider'; // Assuming useAuthContext exists


// Custom Hook for Image Service Operations
function useImageService() {
  const [error, setError] = useState(null);
  const { setLoading, isImageLoading } = useLoadingState(); 
  const [imageDetails, setImageDetails] = useState(null);
  const {sessionID, username} = useAuthContext(); // Assuming useAuthContext exists
  
  // Initialize ApiCaller instance using useMemo
  // static imageDeleteURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-delete.aoendpoint.com';
  // static imageUploadURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-upload.aoendpoint.com';
  // static imageDownloadURL = 'https://'+PortalConfiguration.enviornmentPrefix+'image-download.aoendpoint.com';
  
  const deleteApiCaller = useMemo(() => new ApiCaller(PortalConfiguration.imageDeleteURL), []);
  const uploadAPICaller = useMemo(() => new ApiCaller(PortalConfiguration.imageUploadURL), []);
  const downloadAPICaller = useMemo(() => new ApiCaller(PortalConfiguration.imageDownloadURL), []);

  // Function to fetch image URL
  const fetchImageBlob = useCallback(async (serviceID, objectID) => {
    setLoading('isImageLoading', true);
    setError(null);
    try {
      const downloadParams = {
        GSI_PK_SERVICEID: serviceID,
        GSI_SK_SERVICEOBJECTID: objectID,
      };
      const downloadResponse = await ImageService.downloadImage(downloadAPICaller, sessionID, downloadParams);
      const { imageItem } = downloadResponse;
      setImageDetails(imageItem);
      const { downloadURL} = downloadResponse;
      const blob = await ImageService.downloadImageBlob(downloadURL);
      return blob;
    } catch (error) {
      setError(error);
      return null;
    } finally {
      setLoading('isImageLoading', false);
    }
  }, [setLoading, downloadAPICaller, sessionID]);

  // Function to delete the image
  const deleteImage = useCallback(async (serviceID,objectID) => {
    setLoading('isImageLoading', true);
    setError(null);
    try {
      const deleteParams = {
        GSI_PK_SERVICEID: serviceID,
        GSI_SK_SERVICEOBJECTID: objectID,
      };
      const response = await ImageService.deleteImage(deleteApiCaller, sessionID, deleteParams);
      console.log('Image deleted successfully:', response);
      message.success('Image deleted successfully');
    } catch (error) {
      console.error('Error deleting image:', error);
      setError('Failed to delete image');
      message.error('Error deleting image');
    } finally {
      setLoading('isImageLoading', false);
    }
  }, [setLoading, deleteApiCaller, sessionID]);

  const uploadImage = useCallback(async (file, service, serviceID, objectID, payload = {}) => {
    setLoading('isImageLoading', true);
    setError(null);
    try {
      const additionalParams = {
            PK_UserID: username,
            Filename: file.name,
            payload: payload,
            GSI_PK_SERVICEID: serviceID,
            GSI_SK_SERVICEOBJECTID: objectID,
            type: 'image',
            service: service
        };
      const response = await ImageService.uploadImage(uploadAPICaller, sessionID, file,  additionalParams);
      console.log('Image uploaded successfully:', response);
      return response;
    } catch (error) {
      console.error('Upload failed:', error);
      setError('Failed to upload image');
      message.error('Error uploading image');
    } finally {
      setLoading('isImageLoading', false);
    }
  }, [setLoading, username, uploadAPICaller, sessionID]);

  return {isImageLoading, error, fetchImageBlob, deleteImage, uploadImage, imageDetails};
}

export default useImageService;
