import React, { useEffect, useState } from 'react';
import { Typography, List, Space } from 'antd';
import { useAuthContext } from '../contexts/AuthProvider';
import { useRealmContext } from '../contexts/RealmProvider';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import { useAppContext } from '../contexts/AppProvider';
import './css/TemplatePanel.css'

const { Title } = Typography;
const { Text } = Typography;

const KeyValuesList = () => {
  const {username, sessionID, userPermissionLevel} = useAuthContext();
  const {mode} = useAppContext();
  const {realmID} = useRealmContext();
  const {realmObjectID} = useAccessObjectContext();
  const {realmObjectEdited} = useAccessObjectContext();

  const [data, setData] = useState([]);

  useEffect(() => {
    // Update the data whenever realmID, userID, or realmObjectID changes
    setData([
      {
        title: 'Realm ID',
        value: realmID,
      },
      {
        title: 'Username',
        value: username,
      },
      {
        title: 'SessionID',
        value: sessionID,
      },
      {
        title: 'Realm Object ID',
        value: realmObjectID,
      },
      {
        title: "Realm Objected Edited",
        value: realmObjectEdited,
      },
      {
        title: "userPermissionLevel",
        value: userPermissionLevel,
      },
       {
        title: "mode",
        value: mode
       }
    ]);
  }, [realmID, username, realmObjectID, sessionID, realmObjectEdited, userPermissionLevel]); // Dependency array

  return (
    <div className='fab-template-panel gradient-border'>
      <List className='fab-template-panel'
        header={<Title level={4} className='custom-title center-element'>Global Context Values</Title>}
        bordered
        dataSource={data}
        renderItem={item => (
          <List.Item className="fab-template-panel-item">
            <Space className="fab-template-panel-item-content">
                <Text className='custom-title-2'>{item.title}</Text>
                <Text className='text-error'>{item.value}</Text>
            </Space>
           </List.Item>
        )}
      />
    </div>
  );
};

export default KeyValuesList;
