// CampaignRecordService.js
import { v4 as uuidv4 } from 'uuid';
import useCampaignService from '../hooks/useCampaignService';
import { RecordTypes } from '../types/CampaignTypes';

export const CampaignRecordService = () => {
  const {
    saveCampaignRecord,
    deleteCampaignRecord,
    fetchCampaignRecords,
    deleteCampaign,
    fetchRealmCampaigns,
  } = useCampaignService();

  const fetchCampaign = async (campaignId) => {
    try {
      const campaign = await fetchCampaignRecords(campaignId, RecordTypes.Primary);
      if (campaign && campaign.length > 0) {
        return campaign[0];  
      }
      return null;
    } catch (error) {
      console.error(`Error fetching campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Create a new campaign
  const createCampaign = async (campaignId, realmId, payload) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: uuidv4(),
          GSI_PK_REALMID: realmId,
          payload,
          type: RecordTypes.Primary,
        },
        true
      );
      return result;
    } catch (error) {
      console.error('Error creating campaign:', error);
      throw error;
    }
  };

  // Add user to a campaign
  const addUserToCampaign = async (realmID, campaignId, userId, userData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: userId,
          GSI_PK_REALMID: realmID,
          payload: userData,
          type: RecordTypes.User,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding user ${userId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Add notes to a campaign
  const addNotesToCampaign = async (realmID,campaignId, noteId, noteData, author = 'anonymous') => {
    try {
      noteData.author = author;
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: noteId,
          GSI_PK_REALMID: realmID,
          payload: noteData,
          type: RecordTypes.Note,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding note ${noteId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Add session to a campaign
  const addSessionToCampaign = async (realmID, campaignId, sessionId, sessionData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: sessionId,
          GSI_PK_REALMID: realmID,
          payload: sessionData,
          type: RecordTypes.Session,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding session ${sessionId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Add override realm object to a campaign
  const addOverrideRealmObjectToCampaign = async (realmID,campaignId, objectId, objectData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: objectId,
          GSI_PK_REALMID: realmID,
          payload: objectData,
          type: RecordTypes.OverrideRealmObject,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding override realm object ${objectId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Add campaign story item
  const addCampaignStoryItem = async (realmID,campaignId, storyItemId, storyData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: storyItemId,
          GSI_PK_REALMID: realmID,
          payload: storyData,
          type: RecordTypes.StoryItem,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding story item ${storyItemId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Add campaign character
  const addCampaignCharacter = async (realmID,campaignId, characterId, characterData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: characterId,
          GSI_PK_REALMID: realmID,
          payload: characterData,
          type: RecordTypes.Character,
        },
        true
      );
      return result;
    } catch (error) {
      console.error(`Error adding character ${characterId} to campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Update a campaign character
  const updateCampaignCharacter = async (realmID, campaignId, characterId, characterData) => {
    try {
      const result = await saveCampaignRecord(
        {
          PK_CampaignID: campaignId,
          SK_RecordID: characterId,
          GSI_PK_REALMID: realmID,
          payload: characterData,
          type: RecordTypes.Character,
        },
        false
      );
      return result;
    } catch (error) {
      console.error(`Error updating character ${characterId} in campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Fetch all users for a campaign
  const getCampaignUsers = async (campaignId) => {
    try {
      const users = await fetchCampaignRecords(campaignId, RecordTypes.User);
      return users;
    } catch (error) {
      console.error(`Error fetching users for campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Fetch all notes for a campaign
  const getCampaignNotes = async (campaignId) => {
    try {
      const notes = await fetchCampaignRecords(campaignId, RecordTypes.Note);
      return notes;
    } catch (error) {
      console.error(`Error fetching notes for campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Fetch all sessions for a campaign
  const getCampaignSessions = async (campaignId) => {
    try {
      const sessions = await fetchCampaignRecords(campaignId, RecordTypes.Session);
      return sessions;
    } catch (error) {
      console.error(`Error fetching sessions for campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Fetch all override realm objects for a campaign
  const getCampaignOverrideRealmObjects = async (campaignId) => {
    try {
      const overrideObjects = await fetchCampaignRecords(campaignId, RecordTypes.OverrideRealmObject);
      return overrideObjects;
    } catch (error) {
      console.error(`Error fetching override realm objects for campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Fetch all campaigns related to a specific realm
  const getRealmCampaignsService = async (realmId) => {
    try {
      const campaigns = await fetchRealmCampaigns(realmId);
      return campaigns;
    } catch (error) {
      console.error(`Error fetching campaigns for realm ${realmId}:`, error);
      throw error;
    }
  };

  // Delete a specific type of record from a campaign
  const deleteRecord = async (realmID, campaignId, recordType, recordId) => {
    try {
      
      const result = await deleteCampaignRecord(realmID, campaignId, `${recordId}`);
      return result;
    } catch (error) {
      console.error(`Error deleting ${recordType} ${recordId} from campaign ${campaignId}:`, error);
      throw error;
    }
  };

  // Additional delete functions for specific types
  const deleteUserFromCampaign = async (realmID,campaignId, userId) => {
    return await deleteRecord(realmID,campaignId, RecordTypes.User, userId);
  };

  const deleteNotesFromCampaign = async (realmID, campaignId, noteId) => {
    return await deleteRecord(realmID, campaignId, RecordTypes.Note, noteId);
  };

  const deleteSessionFromCampaign = async (realmID, campaignId, sessionId) => {
    return await deleteRecord(realmID, campaignId, RecordTypes.Session, sessionId);
  };

  const deleteOverrideRealmObjectFromCampaign = async (realmID, campaignId, objectId) => {
    return await deleteRecord(realmID, campaignId, RecordTypes.OverrideRealmObject, objectId);
  };

  const deleteCampaignStoryItem = async (realmID, campaignId, storyItemId) => {
    return await deleteRecord(realmID, campaignId, RecordTypes.StoryItem, storyItemId);
  };

  const deleteCampaignCharacter = async (realmID, campaignId, characterId) => {
    return await deleteRecord(realmID, campaignId, RecordTypes.Character, characterId);
  };

  return {
    createCampaign,
    deleteCampaign,
    fetchCampaign,
    getCampaignRecord: fetchCampaignRecords,
    getRealmCampaigns: getRealmCampaignsService,
    addUserToCampaign,
    addNotesToCampaign,
    addSessionToCampaign,
    addOverrideRealmObjectToCampaign,
    deleteUserFromCampaign,
    deleteNotesFromCampaign,
    deleteSessionFromCampaign,
    deleteOverrideRealmObjectFromCampaign,
    getCampaignUsers,
    getCampaignNotes,
    getCampaignSessions,
    getCampaignOverrideRealmObjects,
    addCampaignStoryItem,
    deleteCampaignStoryItem,
    addCampaignCharacter,
    deleteCampaignCharacter,
    updateCampaignCharacter,
  };
};

export default CampaignRecordService;
