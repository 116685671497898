import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const ImageComponent = ({ blob, imageKey, onDelete }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (blob) {
      const url = URL.createObjectURL(blob);
      setImageURL(url);

      // Cleanup function to revoke the object URL
      return () => {
        URL.revokeObjectURL(url);
      };
    } else {
      setImageURL(null); // Clear the image URL if blob is null
    }
  }, [blob]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {imageURL && (
        <>
          <img
            key={imageKey}
            src={imageURL}
            alt="blob"
            className='fab-panel-card story-presenter element-margined-small'
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain'  // Ensures the image scales while maintaining aspect ratio
            }}
          />
        </>
      )}
    </div>
  );
};

export default ImageComponent;
