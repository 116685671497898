import React, { useState, useEffect } from 'react';
import { Typography, Space } from 'antd';
import ImageComponent from './ImageComponent';
import useImageService from '../hooks/useImageServices';

const { Text } = Typography;

const ImageLoader = ({ realmObject, handleImageItemCreation, handleImageItemDeletion }) => {
  const { error, fetchImageBlob } = useImageService(); // Add deleteImage hook
  const [imageBlob, setImageBlob] = useState(null);
  const [selfLoad, setSelfLoad] = useState({ serviceID: null, objectID: null });
  const [imageAvailable, setImageAvailable] = useState(false);

  const service = 'realm-objects';

  useEffect(() => {
    const setLoading = async () => {
      console.log('RealmObject:', realmObject);
      if (realmObject && realmObject.payload && realmObject.payload.images && realmObject.payload.images.length > 0) {
        setImageAvailable(true);
        const serviceID = realmObject.RealmID;
        const objectID = realmObject.RealmObjectID;
        setSelfLoad({ serviceID, objectID });
        await loadImage(serviceID, objectID);
      } else {
        setImageAvailable(false);
        setSelfLoad({ serviceID: null, objectID: null });
        setImageBlob(null);
      }
    };

    const loadImage = async (serviceID, objectID) => {
      if (serviceID && objectID) {
        try {
          const blob = await fetchImageBlob(serviceID, objectID);
          setImageBlob(blob || null); // Clear if blob is null
        } catch (err) {
          console.error('Error fetching image:', err);
          setImageBlob(null);
        }
      } else {
        setImageBlob(null); // Clear the image immediately if selfLoad is null
      }
    };

    setLoading();
  }, [realmObject, fetchImageBlob]); // Trigger the effect only when realmObject or fetchImageBlob changes

  return (
    <Space id='001113' className=''>
        <div id='00111'>
          {error && error.statusCode !== 404 ? (
            error.statusCode === 403 ? (
              <Text className="text-primary">Image is being checked for security, check back in a while.</Text>
            ) : (
              <Text className='text-primary'>Unable to Load the Image right now.</Text>
            )
          ) : (
            imageAvailable ? (
              <ImageComponent key={selfLoad?.objectID} imageKey={selfLoad?.objectID} blob={imageBlob} />
            ) : (
              null
            )
          )}
        </div>
    </Space>
  ); 
};

export default ImageLoader;
