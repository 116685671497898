import { useState, useEffect, useCallback } from 'react';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import RealmObjectTreeTitle from '../components/RealmObjectTreeTitle';


const useAccessTreeHandler = (datafunction) => {
  const { 
    accessObjectList
  } = useAccessObjectContext();
  
  const [treeData, setTreeData] = useState([]); // State for tree data
  const [expandedKeys, setExpandedKeys] = useState([]); // State for expanded tree keys

  // Function to refresh and update the tree data
  const refreshTreeData = useCallback(async () => {
    if (!datafunction || datafunction().length === 0) {
      return [];
    }
    const tree = buildTree(datafunction());
    setTreeData(tree); // Set the tree data
  }, [accessObjectList]);

  const buildTree = useCallback((items, visited = new Set()) => {
    if (!Array.isArray(items)) {
      return [];
    }
  
    // Group items by realmID
    const realmGroups = items.reduce((acc, item) => {
      const { realmID } = item.payload;
      if (!acc[realmID]) {
        acc[realmID] = [];
      }
      acc[realmID].push(item);
      return acc;
    }, {});
  
    // Build tree nodes by realm group
    return Object.keys(realmGroups).map((realmID) => {
      const children = realmGroups[realmID].map((item) => {
        // Avoid circular references using targetId
        if (visited.has(item.targetId)) {
          console.warn(`Circular reference detected: ${item.targetId}`);
          return null;
        }
        visited.add(item.targetId);
  
        return {
          title: <RealmObjectTreeTitle name={item.payload.name} />,
          key: item.targetId,
          accessObject: item, // Include the original item object as accessObject
        };
      }).filter((node) => node !== null); // Remove null nodes 
  
      return {
        title: `${realmID}`,
        key: `realm-${realmID}`,
        children, // Attach children grouped by realmID
      };
    });
  }, []);
  
  

  // Effect to process AccessObjectList and generate tree data
  useEffect(() => {
    refreshTreeData();
  }, [accessObjectList, refreshTreeData]);

  return {
    treeData, // Tree structure data
    expandedKeys, // Keys to keep track of expanded nodes
    setExpandedKeys, // Function to set expanded nodes
  };
};

export default useAccessTreeHandler;
