import PortalConfiguration from '../configuration/config';
import { CampaignFabricatorAuthTokens } from '../configuration/AuthorizationTokens';

class FeatureFlags {
    constructor() {
        this.flags = this.initializeFlags();
        
    }

    // Table of flags with their allowed environments
    flagConfig = {
        CREATE_REALM:               ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise  
        CREATE_MULTIPLE_CAMPAIGNS:  ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        REALMOBJECT_SHARE_TAB:      ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        DEGREES_OF_SEPERATION:      ["prod","qa","dev"],  // Enabled in PROD, also in lower environments unless specified otherwise
        SOME_FEATURE_FLAG:          ["off"],   // Disabled in all environments
        // Add more flags here
    };

    getUserFlag(AuthorizationToken, flag) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        switch (AuthorizationToken) {
            case CampaignFabricatorAuthTokens.REALM_MASTER:   
            case CampaignFabricatorAuthTokens.GAME_MASTER:
                return this.flags[flag];
            default:
                if (flag === 'REALMOBJECT_SHARE_TAB') {
                    return this.flags[flag] || false; // Return the flag's value or false if undefined
                }
                return false; // Return false for any other flag
        }
    }
    
    initializeFlags() {
        const { env } = PortalConfiguration;
        const enabledFlags = {};
        // Iterate over the flagConfig to determine which flags should be enabled
        Object.keys(this.flagConfig).forEach(flag => {
            const allowedEnvironments = this.flagConfig[flag];
            if (allowedEnvironments.includes(env)) {
                enabledFlags[flag] = true;
            } else {
                enabledFlags[flag] = false;
            }
        });
        this.initialized = true;
        return enabledFlags;
    }
    
    
    setFlag(name, value) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        this.flags[name] = value;
    }

    getFlag(name) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        return this.flags[name];
    }

    isFlagEnabled(name) {
        if (!this.initialized) throw new Error("FeatureFlags not initialized");
        return !!this.flags[name];
    }
}

export default FeatureFlags;
