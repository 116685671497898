import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Row, Col, Typography, Spin } from 'antd'; // Added Spin for loading state
import { useAuthContext } from '../contexts/AuthProvider';
import CustomSpinner from './CustomSpinner';  // Ensure this component is created and imported correctly
import './css/LoginForm.css';  // Ensure this CSS file uses the same variables from App.css

const LoginForm = () => {
    const { Title, Text } = Typography;

    const { login, loading } = useAuthContext();  // Use loading from context
    const [iconifiedTitleProps, setIconifiedTitleProps] = useState(null);

    const onFinish = async (values) => {
        try {
            await login(values.username, values.password);
        } catch (error) {
            message.error('Login failed');
        }
    };

    useEffect(() => {
        setIconifiedTitleProps([
          { key: "title", value: "Campaign Fabricator" },
          { key: "iconFileIndex", value: 30 },
          { key: "iconIndex", value: 0 },
          { key: "iconDisplaySize", value: 65 },
          { key: "titleSize", value: "large"}
        ]);
      }, []);

    return (
        <Row justify="" align="top" className="login-row">
            <Col xs={22} sm={24} md={24} lg={24} xl={24}>
                {loading ? (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <CustomSpinner />
                        <Title level={3} className='custom-title large-size' style={{ display: 'block', marginTop: '20px' }}>
                            Let's Go...
                        </Title>
                    </div>
                ) : (
                    <Form name="login" onFinish={onFinish} layout="vertical" className="login-form panel-background-medium panel-border-light">
                        <Form.Item
                            label="Username"
                            name="username"
                            className='custom-form-item'
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input placeholder="Enter your username" className="large-size"/>
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            className='custom-form-item'
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password placeholder="Enter your password" className="large-size" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} block className="primary large-size">
                                Login
                            </Button>
                        </Form.Item>
                        {/* Sign up link */}
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Text className='text-primary large-size'>
                                Don't have an account? <a href="http://campaignfabricator.com"  className="text-orange large-size" target="_blank" rel="noopener noreferrer">Sign up</a>
                            </Text>
                        </div>
                    </Form>
                )}
            </Col>
        </Row>
    );
};

export default LoginForm;
