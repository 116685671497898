import { Space, Row, Col, Typography } from 'antd';
import IconifiedTitle from './IconifiedTitle';
import StoryPresenter from './StoryPresenter';
import ImageLoader from './ImageLoader';
import './css/PresentationTab.css';

const RealmObjectViewer = ({ realmObject, accessDenied }) => {
  const hasImages = realmObject && realmObject.payload && realmObject.payload.images && realmObject.payload.images.length > 0;
  const realmObjectID = realmObject?.RealmObjectID;  
  const iconifiedTitleProps = accessDenied
    ? {
        title: 'Access Denied',
        iconFileIndex: 0,
        iconIndex: 0,
        iconDisplaySize: 45,
        titleSize: 'large',
        titleEditable: false,
      }
    : realmObject
    ? {
        title: realmObject?.name || 'Unnamed',
        iconFileIndex: realmObject?.payload.iconFileIndex || 0,
        iconIndex: realmObject?.payload.iconIndex || 0,
        iconDisplaySize: 45,
        titleSize: 'large',
        titleEditable: true,
      }
    : {};

  return (
    <Row className="realm-object-viewer">
      <Col xs={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row justify="space-between" align="middle" className="element-margined-medium">
            <Col span={24}>
              <IconifiedTitle {...iconifiedTitleProps} />
            </Col>
          </Row>

          {/* Main Content with Image and Text Wrapping */}
          <Row className="content-wrapper" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {/* Image Section */}
            {hasImages && (
              <Col
                xs={24} sm={8} md={8} lg={8}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', marginBottom: '16px' }}
              >
                <ImageLoader key={realmObjectID} realmObject={realmObject} />
              </Col>
            )}

            {/* Text Content Section with both StoryPresenter components */}
            <Col
              xs={24} sm={hasImages ? 16 : 24} md={hasImages ? 16 : 24} lg={hasImages ? 16 : 24}
              style={{ paddingLeft: hasImages ? '16px' : '0', flexGrow: 1 }}
            >
              <StoryPresenter
                fieldKey="ShortStory"
              />
              <StoryPresenter
                fieldKey="LongStory"
              />
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};

export default RealmObjectViewer;
