export class CampaignFabricatorAuthTokens {

    static ANONYMOUS = null;
    static PLAYER = "3.333";
    static ADVENTURER = "1.0";
    static GAME_MASTER = "1.1";
    static REALM_MASTER = "1.2";
    static ADMINISTRATOR = "1.3";
    static SUPERADMIN = "1000";

    static get allTokens() {
        return [
            { name: "PLAYER", value: CampaignFabricatorAuthTokens.PLAYER },
            { name: "ADVENTURER", value: CampaignFabricatorAuthTokens.ADVENTURER },
            { name: "GAME_MASTER", value: CampaignFabricatorAuthTokens.GAME_MASTER },
            { name: "REALM_MASTER", value: CampaignFabricatorAuthTokens.REALM_MASTER },
            { name: "ADMINISTRATOR", value: CampaignFabricatorAuthTokens.ADMINISTRATOR },
            { name: "SUPERADMIN", value: CampaignFabricatorAuthTokens.SUPERADMIN },
        ];
    }
    static get friendlyTokenStrings() {
        return {
            [CampaignFabricatorAuthTokens.PLAYER]: "Player",
            [CampaignFabricatorAuthTokens.ADVENTURER]: "Adventurer",
            [CampaignFabricatorAuthTokens.GAME_MASTER]: "Game Master",
            [CampaignFabricatorAuthTokens.REALM_MASTER]: "Realm Master",
            [CampaignFabricatorAuthTokens.ADMINISTRATOR]: "Administrator",
            [CampaignFabricatorAuthTokens.SUPERADMIN]: "Super Administrator",
        };
    }
    static friendlyTokenString(token) {
        return CampaignFabricatorAuthTokens.friendlyTokenStrings[token] || "Unknown";
    }
    static friendlyTokenStringFromValue(tokenValue) {
        return CampaignFabricatorAuthTokens.friendlyTokenString(tokenValue);
    }
}

export default CampaignFabricatorAuthTokens;