import React from 'react';
import { Space, Typography } from 'antd';
import IconDisplay from './IconDisplay'; // Adjust the import path as necessary
import "./css/IconifiedText.css"; // Ensure you create and import this CSS file


const { Text } = Typography;

const IconifiedText = ({ 
  text, 
  iconFileIndex, 
  iconIndex, 
  iconDisplaySize, 
  iconPosition = "left",
  textType = "medium",
  textStyle = "secondary",
  textTreatment = '',
}) => {
  const noIcon = (iconIndex === null);

  if(iconPosition === "right") {
    return (
      <Space className="iconified-text">
        <Text className={`custom-text text-${textType} text-${textStyle} ${textTreatment ? textTreatment : ''}`}>
          {text}
        </Text>
        {!noIcon &&
        <IconDisplay
          imageTableIndex={iconFileIndex ? iconFileIndex : 0}
          iconIndex={iconIndex ? iconIndex : 0}
          displayWidth={iconDisplaySize ? iconDisplaySize : 25}
          displayHeight={iconDisplaySize ? iconDisplaySize : 25}
          className='fab-card-header-icon'
        />}
      </Space>
    );
  } else{
    return (
      <Space className="iconified-text">
         {!noIcon &&
        <IconDisplay
          imageTableIndex={iconFileIndex ? iconFileIndex : 0}
          iconIndex={iconIndex ? iconIndex : 0}
          displayWidth={iconDisplaySize ? iconDisplaySize : 25}
          displayHeight={iconDisplaySize ? iconDisplaySize : 25}
          className='fab-card-header-icon'
        />}
        
        <Text className={`custom-text text-${textType} text-${textStyle}`}>
          {text}
        </Text>
      </Space>
    );
  }
};

export default IconifiedText;
