import React, { createContext, useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { CampaignRecordService } from '../services/CampaignRecordService';
import { useRealmContext } from './RealmProvider'; // Import the realm context
import { useAuthContext } from './AuthProvider'; // Import the auth context

const CampaignsContext = createContext();

export const useCampaignsContext = () => {
    const context = useContext(CampaignsContext);
    if (!context) {
        throw new Error('useCampaignsContext must be used within an PlayersCampaignsProvider');
    }
    return context;
};

export const PlayerCampaignsProvider = ({ children }) => {
    const {
        getRealmCampaigns,
        getCampaignRecord,
        getCampaignUsers,
        getCampaignNotes,
        getCampaignSessions,
        getCampaignOverrideRealmObjects,
        fetchCampaign,
      } = CampaignRecordService();
      
  const { setRealmID } = useRealmContext(); // Get realmID from the RealmProvider
  const [campaignList, setCampaignList] = useState([]); //list of the campaigns the player is in.
  const [campaign, setCampaign] = useState(null); //currently selected Campaign
  const [campaignPrimaryRecord, setCampaignPrimaryRecord] = useState(null);
  const [campaignNotes, setCampaignNotes] = useState([]);
  const [campaignUsers, setCampaignUsers] = useState([]);
  const [campaignSessions, setCampaignSessions] = useState([]);
  const {userID} = useAuthContext();

  useEffect(() => {
    //get accesscontrol recoards for this player targetType = campaign-access
  }, [userID]);

  useEffect(() => {
    const fetchData = async () => {
      //get CampaignNotes for the selected campaign
      if (campaign) {
        updateCampaignNotes(campaign);
        updateCampaignUsers(campaign);
        const campaignRecord = await fetchCampaign(campaign);
        console.log('retrevied',campaignRecord,campaignRecord?.GSI_PK_REALMID);
        setRealmID(campaignRecord?.GSI_PK_REALMID || null);
        setCampaignPrimaryRecord(campaignRecord);
      } else {
        setCampaignNotes([]);
        setCampaignUsers([]);
      }
    };

    fetchData();
  }, [campaign]);

  
  const updateCampaignUsers = useCallback((campaignToLoad) => {
    if (campaignToLoad) {
      // Fetch all related records for the selected campaign
      const fetchRecords = async () => {
        try {
          const users = await getCampaignUsers(campaignToLoad);
          setCampaignUsers(users);
        } catch (error) {
          console.error('Failed to fetch campaign users:', error);
          setCampaignUsers([]);
        }
      };

      fetchRecords();
    } else {
      setCampaignUsers([]);
    }
  }, [getCampaignUsers]);
  
  const updateCampaignNotes = useCallback((campaignToLoad) => {  
    if (campaignToLoad) {
      // Fetch all related records for the selected campaign
      const fetchRecords = async () => {
        try {
          const notes = await getCampaignNotes(campaignToLoad);
          setCampaignNotes(notes);
        } catch (error) {
          console.error('Failed to fetch campaign notes:', error);
          setCampaignNotes([]);
        }
      };

      fetchRecords();
    } else {
      setCampaignNotes([]);
    }
  }, [getCampaignNotes]);

  // Function to set the active campaign
  const selectCampaign = (campaignId) => {
    const selectedCampaign = campaignList.find(c => c.PK_CampaignID === campaignId);
    if (selectedCampaign) {
      setCampaign(selectedCampaign);
    } else {
      setCampaign(null);
    }
  };

  const contextValue = {
    campaignList,
    setCampaignList,
    campaign,
    setCampaign,
    campaignNotes,
    setCampaignNotes,
    updateCampaignNotes,
    campaignUsers, 
    setCampaignUsers,
    campaignSessions,
    setCampaignSessions,
    selectCampaign,
    campaignPrimaryRecord, 
    setCampaignPrimaryRecord,
  };

  return (
    <CampaignsContext.Provider value={contextValue}>
      {children}
    </CampaignsContext.Provider>
  );
};

export default PlayerCampaignsProvider;