import React, { createContext, useState, useContext, useEffect } from 'react';

const RealmContext = createContext();

export const useRealmContext = () => {
  const context = useContext(RealmContext);
  if (!context) {
    throw new Error('useRealmContext must be used within a RealmProvider');
  }
  return context;
};

export const RealmProvider = ({ children }) => {
  const [realmID, setRealmID] = useState(() => {
    
    return null;
  });

  return (
    <RealmContext.Provider value={{ realmID, setRealmID }}>
      {children}
    </RealmContext.Provider>
  );
};

export default RealmProvider;
