import React, { useEffect, useState } from 'react';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import { useRealmContext } from '../contexts/RealmProvider';
import ApiCaller from '../services/ApiCaller';
import PortalConfiguration from '../configuration/config';


export const RealmObjectManager = () => {

    const { realmID, setRealmID } = useRealmContext();
    const { realmObjectSource, setRealmObject } = useAccessObjectContext();
    const [lastLoadedRealmObject, setLastLoadedRealmObject] = useState(null);
    const apiCaller = new ApiCaller(PortalConfiguration.realmobjectsURL);
  
  const {
       realmObjectID, 
       setLoading,
       fetchRealmObject
    } = useAccessObjectContext();
        
    // Load the realm object whenever realmObjectID changes
    useEffect(() => {
        console.log('RealmObjectManager : ',realmObjectSource, realmObjectID, realmID);
        if (realmObjectSource !== 'accesstree') {
            return; // Exit early if the source is not the access tree
        }
       
        if (!realmObjectID) {
            setRealmObject(null);
            setLastLoadedRealmObject(null);
            return; // Exit early if there's no realmObjectID
        }
        // Async function to fetch the realm object and related data
        async function fetchData() {
            try {
                setLoading('isLoadingObject', true);
                setLoading('isLoadingItem', true);
                setLoading('isFetchingLinks', true);
                fetchRealmObject(realmID, realmObjectID);
                
            } catch (error) {
                console.error("Error fetching realm object data: ", error);
            } finally {
                setLoading('isFetchingLinks', false);
                setLoading('isLoadingItem', false);
                setLoading('isLoadingObject', false);
            }
        }
        if (realmObjectID !== lastLoadedRealmObject) {
            setLastLoadedRealmObject(realmObjectID);
            fetchData();
        }
    }, [realmObjectID]);

    return <div></div>;
};
