import { useState, useCallback, useMemo } from 'react';
import AccessControlService from '../services/AccessControl'; // Adjust the path based on your file structure
import ApiCaller from '../services/ApiCaller';
import { message } from 'antd';
import PortalConfiguration from '../configuration/config';
import { useLoadingState } from './useLoadingState';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import AccessObject from '../models/accessControl'; // Adjust this import to the actual path

// Hook to manage access control actions
const useAccessControl = () => {
  const apiCaller = useMemo(() => new ApiCaller(PortalConfiguration.accessControlURL), []);

  // State to track the list of access records
  const { setAllowedAccessors , setAccessObjectList} = useAccessObjectContext(); 
  const { sessionID } = useAuthContext();
  const { setLoading, isLoading } = useLoadingState(); // Custom loading hook, similar to your example
  const [accessError, setAccessError] = useState(null); // State to track any errors

  // Create a new access record
  const createAccess = useCallback(async (newAccessData) => {
    setLoading('isAccessCreating', true);
    setAccessError(null); // Clear any previous errors
    try {
      const accessObject = new AccessObject(newAccessData)
      const result = await AccessControlService.createAccess(apiCaller, sessionID, accessObject);
      const savedObject = new AccessObject(result); // Wrap the saved record in AccessObject
      setAllowedAccessors(prevList => [...prevList, savedObject]); // Add the new access record (AccessObject)
      message.success('Access record created successfully');
    } catch (error) {
      setAccessError(error);
      message.error('Error creating access record');
      console.error('Error creating access:', error);
    } finally {
      setLoading('isAccessCreating', false);
    }
  }, [apiCaller, sessionID, setAllowedAccessors, setLoading]);

  // Delete an access record
  const deleteAccess = useCallback(async (accessId, targetId) => {
    setLoading('isAccessDeleting', true);
    setAccessError(null); // Clear any previous errors
    try {
      const accessObject = new AccessObject({ accessId, targetId }); // Wrap in AccessObject
      await AccessControlService.deleteAccess(apiCaller, sessionID, accessObject.accessId, accessObject.targetId);
      setAllowedAccessors(prevList => prevList.filter(record => !(record.accessId === accessId && record.targetId === targetId)));
      message.success('Access record deleted successfully');
    } catch (error) {
      setAccessError(error);
      message.error('Error deleting access record');
      console.error('Error deleting access:', error);
    } finally {
      setLoading('isAccessDeleting', false);
    }
  }, [apiCaller, sessionID, setAllowedAccessors, setLoading]);

  // Retrieve access records for a specific user (accessor)
  const getTargetList = useCallback(async (userId) => {
    setLoading('isAccessFetching', true);
    setAccessError(null); // Clear any previous errors
    try {
      const result = await AccessControlService.getTargetList(apiCaller, sessionID, userId);
      const accessObjects = result.map(record => new AccessObject(record)); // Wrap each record in AccessObject
      setAccessObjectList(accessObjects); // Set the access list to the retrieved records
      console.log('Access Objects:', accessObjects);
      //for each accessObject in accessObjects that has the type of campaign-access, get the accessObjects for that campaign and add those to the list of accessObjects
      for (const accessObject of accessObjects) {
        if (accessObject.targetType === 'campaign-access') {
          const campaignAccessObjects =  await AccessControlService.getTargetList(apiCaller, sessionID, accessObject.targetId);
          console.log('Campaign Access Objects:', campaignAccessObjects);
          setAccessObjectList(prevList => [...prevList, ...campaignAccessObjects]);
        }
      }
      
      return accessObjects;
    } catch (error) {
      setAccessError(error);
      console.error('Error fetching access records:', error);
      setLoading('isAccessFetching', false);
      throw new Error('Failed to fetch target list');
    } finally {
      setLoading('isAccessFetching', false);
    }
  }, [apiCaller, sessionID, setAccessObjectList, setLoading]);

  // Retrieve access records for a specific target (accessors)
  const getAccessorList = useCallback(async (targetId) => {
    setLoading('isAccessFetching', true);
    setAccessError(null); // Clear any previous errors
    try {
      const result = await AccessControlService.getAccessorList(apiCaller, sessionID, targetId);
      const accessObjects = result.map(record => new AccessObject(record)); // Wrap each record in AccessObject
      console.log('Access Objects:', accessObjects);
      setAllowedAccessors(accessObjects); // Set the access list to the retrieved records
      return accessObjects;
    } catch (error) {
      setAccessError(error);
      message.error('Error fetching access records');
      console.error('Error fetching access records:', error);
    } finally {
      setLoading('isAccessFetching', false);
    }
  }, [apiCaller, sessionID, setAllowedAccessors, setLoading]);

  return {
    accessError,          // Any error that occurred during an operation
    isLoading,            // Boolean indicating if any process is running
    createAccess,         // Function to create a new access record
    deleteAccess,         // Function to delete an access record
    getTargetList,        // Function to get target list by userId
    getAccessorList,      // Function to get accessor list by targetId
  };
};

export default useAccessControl;
