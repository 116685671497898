import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Drawer, Space, Typography, Grid, Layout } from 'antd';
import { ExportOutlined, LoginOutlined } from "@ant-design/icons";
import IconifiedTitle from './IconifiedTitle';
import UserStatusPanel from './UserStatusPanel';
import UserStatusAvatar from './UserStatusAvatar';
import './css/SiteHeader.css';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider';
import { useAuthContext } from '../contexts/AuthProvider';
import { useAppContext } from '../contexts/AppProvider';

const { useBreakpoint } = Grid;
const { Text, Title } = Typography;
const { Header } = Layout;

const SiteHeader = () => {
  const [iconifiedTitleProps, setIconifiedTitleProps] = useState(null);
  const { referenceID, mode } = useAppContext();
  const { loggedIn, showLogin, setShowLogin } = useAuthContext();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { realmObject, accessDenied } = useAccessObjectContext();
  
  const screens = useBreakpoint();

  useEffect(() => {
    if (showLogin !== drawerVisible) {
      setDrawerVisible(showLogin);
    }
  }, [showLogin, drawerVisible]);

  useEffect(() => {
    setIconifiedTitleProps({
      title: "Campaign Fabricator",
      iconFileIndex: 30,
      iconIndex: 0,
      iconDisplaySize: 65,
      titleSize: "large",
    });
  }, []);

  const showDrawer = () => setShowLogin(true);
  const closeDrawer = () => setShowLogin(false);

  return (
    <Header className='parent-container'>
      <Row className="site-header-row" align="top" justify="center">
        <Col xs={24} sm={16} md={16} lg={16} xl={16} className="logo-col">
          <IconifiedTitle {...iconifiedTitleProps} />
        </Col>
        <Col xs={24} sm={8} md={8} lg={8} xl={8} className="input-col">
          <Space direction="vertical" align="center" size={8} className='mySpace'>
            {loggedIn ? (
              <UserStatusAvatar showDrawer={showDrawer} />
            ) : (
              (referenceID) && (
                <>
                  <Space direction="horizontal" align="center" className='element-margined-nill'>
                    <Button icon={<LoginOutlined />} onClick={showDrawer} className='primary'>
                      Sign In
                    </Button>
                    <Button 
                      type="secondary" 
                      icon={<ExportOutlined />} 
                      href="http://campaignfabricator.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="secondary-btn button-margined-small"
                    >
                      {screens.lg && "Sign Up"}
                    </Button>
                  </Space>
                  <Text type="secondary" className='text-secondary element-margined-nill'>
                    {screens.lg ? "Get your own realm and create your own world." : "Upgrade and create your own world."}
                  </Text>
                </>
              )
            )}
           
          </Space>
        </Col>
        <Drawer title="User Status" placement="right" onClose={closeDrawer} open={drawerVisible}>
          <UserStatusPanel />
        </Drawer>
      </Row>
    </Header>
  );
};

export default SiteHeader;
