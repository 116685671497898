import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import FeatureFlags from '../configuration/featureFlags'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';

// Create the context
export const AppContext = createContext();

// Create a custom hook to use the AppContext
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

// Create the provider component
export const AppProvider = ({ children }) => {
  const [semifor, setSemifor] = useState(false);
  const [featureFlags] = useState(new FeatureFlags());
  const navigate = useNavigate();

  const [mode, setRealMode] = useState('');

  // Use useLocation to get the query parameters
  const location = useLocation();
  const referenceID = useMemo(() => new URLSearchParams(location.search).get('referencedID'), [location.search]);
  if (new URLSearchParams(location.search).get('referencedID') && !mode) {
    setRealMode('referenceID');
  }
    
  const updateURLParams = useCallback((params) => {
    const newUrl = `${location.pathname}?${params.toString()}`;
    navigate(newUrl, { replace: true });
  }, [location.pathname]);

  const setMode = useCallback(async (value) => {
    console.log('Setting mode:', value);
    if (['referenceID', 'realmObject', 'campaign'].includes(value)) {
      setRealMode(value);
      if (value !== 'referenceID') {
        const params = await new URLSearchParams(location.search);
        if (params.has('referencedID')) {
          params.delete('referencedID');
          updateURLParams(params); // Update the URL
        }
      }
    } else {
      throw new Error('Invalid mode value');
    }
  }, [location.search, updateURLParams]);
  

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(() => ({
    semifor,
    setSemifor,
    featureFlags,
    referenceID,
    mode,
    setMode
  }), [semifor, featureFlags, referenceID, mode, setMode]);

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};
