import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";

const ChangePasswordForm = ({ onFinish, loading, drawerVisible, handleCancel }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State to manage button's disabled status
  const [form] = Form.useForm(); // Form instance

  const validatePasswordStrength = (_, value) => {
    // Updated regex to allow special characters
    if (value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{8,}$/.test(value)) {
      setIsButtonDisabled(false);
      return Promise.resolve();
    }
    setIsButtonDisabled(true); // Reset button state when password changes
    return Promise.reject(new Error('Password must be at least 8 characters long, include both upper and lower case letters, contain at least one number, and one special character.'));
  };

  // Custom validator to check if passwords match
  const validatePasswordMatch = (_, value) => {
    if (!value || form.getFieldValue('newPassword') === value) {
      setIsButtonDisabled(false);
      return Promise.resolve();
    }
    setIsButtonDisabled(true);
    return Promise.reject(new Error('The two passwords do not match!'));
  };

  useEffect(() => {
    if (!drawerVisible) {
      form.resetFields(); // Reset the form fields when the drawer is closed
    }
  }, [drawerVisible, form]);

  return (
    <Form
      form={form}
      name="changePassword"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: '300px' }}
    >
      <Form.Item
        label="New Password"
        name="newPassword"
        rules={[
          { required: true, message: 'Please input your new password!' },
          { validator: validatePasswordStrength }
        ]}
        className='custom-form-item'
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Confirm New Password"
        name="newPasswordCheck"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: 'Please confirm your new password!' },
          { validator: validatePasswordMatch }
        ]}
        className='custom-form-item'
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          type="default"
          onClick={handleCancel} // Trigger the cancel function passed from the parent
          className='secondary-btn element-margined-right-small'
        >
          Cancel
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={isButtonDisabled} // Controlled by state
          className="primary"
        >
          Change Password
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePasswordForm;
