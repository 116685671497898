import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Space, Button, Card } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import IconifiedTitle from './IconifiedTitle';
import "../styles/App.css";
import './css/SiteHeader.css'; // Ensure the path is correct
import './css/NothingToSeeYet.css';
import { useAuthContext } from '../contexts/AuthProvider';

const { Title, Text } = Typography;

const NothingtoSeeYet = () => {
  const [iconifiedTitleProps, setIconifiedTitleProps] = useState(null);
  const {setShowLogin} = useAuthContext();

  useEffect(() => {
    setIconifiedTitleProps([
      { key: "title", value: "Campaign Fabricator" },
      { key: "iconFileIndex", value: 30 },
      { key: "iconIndex", value: 0 },
      { key: "iconDisplaySize", value: 65 },
      { key: "titleSize", value: "large"}
    ]);
  }, []);

  const props = iconifiedTitleProps ? iconifiedTitleProps.reduce((acc, curr) => {
    acc[curr.key] = curr.value;
    return acc;
  }, {}) : {};

  const handleLogin = () => {
    console.log('Login clicked');
    setShowLogin(true);
  }

  return (
<div className="centered-container">
  <Card className="centered-card" bordered={false}>
    <Space direction="vertical" align="center" size="large" style={{ width: '100%' }}>
      <IconifiedTitle {...props} />
      <Title level={4} className="signup-title">
        Welcome to Campaign Fabricator, your ultimate tabletop RPG manager and enhancer! 
        The world's best on-demand custom homebrew world-building platform. 
        Designed with powerful AI support that is completely optional to use.
      </Title>
      <a href="http://campaignfabricator.com" target="_blank" rel="noopener noreferrer">
        <Button 
          type="primary" 
          icon={<ExportOutlined />} 
          shape={window.innerWidth < 576 ? "circle" : "default"}
          size="large"
          className="signup-btn"
        >
          {window.innerWidth >= 576 && "Sign Up"}
        </Button>
      </a>
      <Text type="secondary" className='text-secondary' style={{ fontSize: '16px' }}>
        Elevate Your Storytelling to New Heights
      </Text>
      <Text type="secondary" className='text-secondary' style={{ fontSize: '16px' }}>
        Already have an account? <Text className="text-highlight" onClick={handleLogin}>Log in</Text>
      </Text>
    </Space>
  </Card>
</div>

  );
};

export default NothingtoSeeYet;
