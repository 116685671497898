import React, { useState } from 'react';
import { Space, Typography, Input } from 'antd';
import IconDisplay from './IconDisplay'; // Adjust the import path as necessary
import "./css/IconifiedTitle.css";
import "../styles/App.css";

const { Title } = Typography;

const IconifiedTitle = ({ title, iconFileIndex, iconIndex, iconDisplaySize, onChange, titleSize = "medium", titleEditable = false, iconPosition = 'left' }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(title);

  const sizeMap = {
    "extrasmall": 4,
    "small": 3,
    "medium": 2,
    "large": 1
  };
  const size = sizeMap[titleSize.toLowerCase()] || sizeMap["medium"];

  const toggleEdit = () => {
    if (isEditing && onChange) {
      onChange(editTitle);
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    setEditTitle(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && onChange) {
      onChange(editTitle);
      setIsEditing(false);
    } else if (e.key === 'Escape') {
      setEditTitle(title);
      setIsEditing(false);
    }
  };

  const handleClick = () => {
    if (titleEditable) {
      setEditTitle(title);
      setIsEditing(true);
    }
  };

  if(iconPosition === 'left')
  {
    return (
      <Space className="iconified-title" >
        <IconDisplay
          imageTableIndex={iconFileIndex}
          iconIndex={iconIndex}
          displayWidth={iconDisplaySize ? iconDisplaySize : 25}
          displayHeight={iconDisplaySize ? iconDisplaySize : 25}
          className='fab-card-header-icon'
        />
        {isEditing ? (
          <Input
            className="custom-title-input"
            value={editTitle}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={toggleEdit}
            autoFocus
          />
        ) : (
          <Title level={size} className="custom-title iconified-title-text">{title}</Title>
        )}
      </Space>
    );
  } else
  {
    return (
      <Space className="iconified-title">
       
        {isEditing ? (
          <Input
            className="custom-title-input"
            value={editTitle}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={toggleEdit}
            autoFocus
          />
        ) : (
          <Title level={size} className="custom-title iconified-title-text">{title}</Title>
        )}
         <IconDisplay
          imageTableIndex={iconFileIndex}
          iconIndex={iconIndex}
          displayWidth={iconDisplaySize ? iconDisplaySize : 25}
          displayHeight={iconDisplaySize ? iconDisplaySize : 25}
          className='fab-card-header-icon'
        />
      </Space>
    );
  }
 
};

export default IconifiedTitle;
