import React, { useEffect, useState } from 'react';
import { Tree, Typography } from 'antd';
import useAccessTreeHandler from '../hooks/useAccessTreeHandler'; // Custom hook
import './css/RealmObjectsDisplay.css';
import './css/TreeControl.css';
import { useAccessObjectContext } from '../contexts/AccessObjectProvider'; // Custom context
import { useRealmContext } from '../contexts/RealmProvider';
import { useAppContext } from '../contexts/AppProvider'; // Custom context

const RealmObjectTree = ({ realmObject, setContentLoading, title, myMode = 'realmObject' }) => {
  const { Title } = Typography;
  const { setLoading, realmObjectID, setRealmObjectID, setRealmObjectSource, setAccessDenied, realmObjects } = useAccessObjectContext();
  const { mode, setMode } = useAppContext();
  
  const { setRealmID } = useRealmContext();
  const { treeData, expandedKeys, setExpandedKeys } = useAccessTreeHandler(realmObjects);
  const [selectedKeys, setSelectedKeys] = useState([realmObject?.RealmObjectID]);
  
  const onSelect = (keys, { node }) => {
    try {
      // Check if a valid child node is selected and if it's not already selected
      if (keys.length > 0 && node?.accessObject && realmObjectID !== keys[0]) {
        const selectedItem = node.accessObject; // Get the full item object from the node
        console.log('Selected RealmObject:', keys[0]);
        setRealmID(selectedItem.payload.realmID); // Set realmID from the selected item's payload
        setMode(myMode);
        setAccessDenied(false);
        setRealmObjectSource('accesstree');
        setContentLoading(true);
        setRealmObjectID(keys[0]);
        setSelectedKeys(keys); // Update the selectedKeys state with the new selection
      }
    } catch (error) {
      console.error('Error selecting RealmObject:', error);
    }
  };

  // Reset selectedKeys and realmObjectID when mode changes away from 'realmObject'
  useEffect(() => {
    if (mode !== 'realmObject') {
      setRealmObjectID(null); // Clear the selected object ID
      setSelectedKeys([]); // Clear the selected keys in the tree
    }
  }, [mode, setRealmObjectID]);

  return (
    <>
      <Title level={3} className='custom-title element-margined-small'>
        {title}
      </Title>
      <Tree
        className="realm-object-tree"
        treeData={treeData}
        selectedKeys={selectedKeys}
        expandedKeys={expandedKeys}
        onExpand={setExpandedKeys}
        onSelect={onSelect}
        expandAction="click" // Expand node on entire row click
      />
    </>
  );
};

export default RealmObjectTree;
