import React from 'react';
import { Button, Form, Input, Typography, Card, Tag } from 'antd';
import { DeleteFilled, UserAddOutlined, WarningOutlined } from '@ant-design/icons';
import './css/CampaignPlayerList.css'; 

const { Title, Text } = Typography;

const CampaignPlayerList = ({
  campaignUsers,
  showDeleteUserConfirm,
  handleAddUser,
  userForm,
  errorMessage,
}) => {
  return (
    <Card className="fab-panel-card top-card" title={<Title level={4} className="custom-title center-title">Players</Title>}>
      {/* List of Users as Tags */}
      <div className="player-tag-list">
        {campaignUsers.length > 0 ? (
          campaignUsers.map((user) => (
            <Tag
              key={user.PK_UserID}
              className="player-tag"
            >
              {user.payload?.name ?? 'Unnamed Player'}
            </Tag>
          ))
        ) : (
          <Text className="text-secondary">No players added yet.</Text>
        )}
      </div>
    </Card>
  );
};

export default CampaignPlayerList;
